export default {
  constructosPath: "./constructos",
  constructosOut: "./js/constructos",

  entry: {
    pc: "./js/pc.js",
    mobile: "./js/mobile.js",
    app: "./js/app.js",
    trafficfactory1: "./js/trafficfactory1.js",
  },
  out: {
    pc: "./release/pc/",
    mobile: "./release/mobile/",
    app: "./release/app/",
    trafficfactory1: "./release/trafficfactory1",
  },
  folderName: "/notLogged",
  sass: "./sass",
  defaultLang: "pt-br",
  cssOut: "./release",
  icons: "./icons",
};
